import { Sanity$TextBlock } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';

type Props = {
  heading: Sanity$TextBlock[];
  headingStyles: string;
};

const CustomHeading = ({ heading, headingStyles }: Props) => {
  return (
    <PortableText
      value={heading}
      components={{
        block: {
          h2: ({ children }) => <h2 className={headingStyles}>{children}</h2>,
          h3: ({ children }) => <h3 className={headingStyles}>{children}</h3>,
          h4: ({ children }) => <h4 className={headingStyles}>{children}</h4>,
        },
      }}
    />
  );
};

export default CustomHeading;
