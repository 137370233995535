import Checkmark from '@otovo/shared/components/Icons/Checkmark/Checkmark';
import AddressSearchBar from '@otovo/shared/components/InterestRegistration/AddressSearch/AddressSearchBar';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { handleInterestCreated } from '@otovo/shared/lib/handleInterestCreated/handleInterestCreated';
import { createQueryParamsFromMatchedPartner } from '@otovo/shared/lib/handleInterestCreated/partnerQueryParamHelpers';
import { Cloud$Interest } from '@otovo/shared/types/cloudApi';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { Sanity$AddressSearchStepsSection } from '@otovo/shared/types/sanityTypes';
import { useRouter } from 'next/router';
import housesOnHills from '@otovo/shared/images/houses_on_hill_with_clouds.png';
import houseOnHillsNoMobile from '@otovo/shared/images/houses_on_hill.png';
import CustomHeading from './CustomHeading';
import { RowContext } from '../../SanityLandingPage/types';

type Props = {
  content: Sanity$AddressSearchStepsSection;
  context: RowContext;
};
const AddressSearchBulletSection = ({ content, context }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const { ...restRouterParams } = useRouter();
  const locale = restRouterParams.locale as Otovo$Locale;
  const interestCreatedArgs = {
    locale: locale || BU_CONFIG.locale,
    redirectBaseUrl: BU_CONFIG.BREEZE_REDIRECT_BASE_URL,
    queryParams: createQueryParamsFromMatchedPartner(BU_CONFIG.matchedPartner),
  };

  const { mainHardwareType } = context;

  const headingStyles =
    'text-lilac-20 mb-4 text-2xl font-medium md:text-3xl xl:text-4xl';

  return (
    <div className="relative overflow-hidden bg-[#1b2438] p-8">
      <div className="m-auto grid max-w-7xl grid-cols-8 grid-rows-2 lg:p-8">
        <div className="col-span-full text-[#E6E9FF] lg:col-span-5">
          <CustomHeading
            heading={content.heading}
            headingStyles={headingStyles}
          />
          {content.steps.length &&
            content.steps.map((step) => (
              <div key={step} className="mb-3 flex gap-4 text-sm md:text-lg">
                <Checkmark size="4" position="relative" top="1" />
                {step}
              </div>
            ))}
        </div>
        <div className="z-10 col-span-full row-start-2 mt-8 lg:col-span-5 lg:mt-10">
          <AddressSearchBar
            locale={locale}
            mainHardwareType={mainHardwareType}
            submissionMethod="javascript"
            onInterestCreated={(interest: Cloud$Interest) => {
              handleInterestCreated({
                interest,
                ...interestCreatedArgs,
              });
            }}
          />
        </div>
      </div>
      <img
        src={housesOnHills}
        alt="houses-on-hills"
        className="absolute bottom-0 right-0 hidden lg:block"
      />
      <img
        src={houseOnHillsNoMobile}
        alt="houses-on-hills"
        className="absolute bottom-0 right-0 lg:hidden"
      />
    </div>
  );
};
export default AddressSearchBulletSection;
